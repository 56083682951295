import * as React from 'react';

import { Col, Row } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { faCheck } from '@fortawesome/pro-regular-svg-icons';
import { useScreenInfo } from '../../utils/ScreenInfo';
import { useSignUp } from './SignUpContext';
import { TermStar } from './TermStar';

export const TermsOfServiceForEvents: React.FunctionComponent = () => {
  const { state } = useSignUp();
  const screen = useScreenInfo();

  const params = new URLSearchParams(window.location.search);
  const referral = params.has('r') ? params.get('r') : null;
  const isSFMC = referral === 'SFMC';
  const isCGEMP = referral === 'CG-EMP';
  const isCGHH = referral === 'CG-HH';

  const hasReferral = isSFMC || isCGEMP || isCGHH;

  return (
    <>
      <Row>
        <Col md={6}>
          <div className={`text-center ${screen.isPhone ? 'my-1' : 'my-4'}`}>
            <span className="fs-5">
              You only pay if <br />
              we save you money!
            </span>
            <div className="display-4">
              25%
              <div className="d-inline-flex align-items-start flex-column ms-1 align-middle " style={{ width: 30, marginTop: -10 }}>
                <div className="fs-6">&nbsp;</div>
                <div className="fs-6 align-bottom mt-1 text-muted">
                  <del>50%</del>
                </div>
              </div>
            </div>
            <span className="fs-4 ms-2">Of Your Estimated Tax Savings</span>
          </div>
        </Col>
        <Col md={6}>
          <div style={{ width: screen.isPhone ? '100%' : 360 }} className={classNames({ 'mx-auto': screen.isPhone })}>
            <div className="d-flex bg-white rounded-top mt-3">
              <span className="text-orange border-end border-dark py-1 px-4 me-3">
                <FontAwesomeIcon icon={faCheck} />
              </span>
              <span className="d-inline p-1 small">Unlimited Client Support</span>
            </div>
            <div className="d-flex bg-light">
              <span className="text-orange border-end border-dark py-1 px-4 me-3">
                <FontAwesomeIcon icon={faCheck} />
              </span>
              <span className="d-inline p-1 small">Appointed as Your Tax Representative</span>
            </div>
            <div className="d-flex bg-white">
              <span className="text-orange border-end border-dark py-1 px-4 me-3">
                <FontAwesomeIcon icon={faCheck} />
              </span>
              <span className="d-inline p-1 small">File Protest</span>
            </div>
            <div className="d-flex bg-light">
              <span className="text-orange border-end border-dark py-1 px-4 me-3">
                <FontAwesomeIcon icon={faCheck} />
              </span>
              <span className="d-inline p-1 small">Review & Analyze Evidence</span>
            </div>
            <div className="d-flex bg-white">
              <span className="text-orange border-end border-dark py-1 px-4 me-3">
                <FontAwesomeIcon icon={faCheck} />
              </span>
              <span className="d-inline p-1 small">Prepare & Present Your Appeal</span>
            </div>
            <div className="d-flex bg-light rounded-bottom mb-3">
              <span className="text-orange border-end border-dark py-1 px-4 me-3">
                <FontAwesomeIcon icon={faCheck} />
              </span>
              <span className="d-inline p-1 small">Take Back Control of Your Property Taxes</span>
            </div>
          </div>
        </Col>
      </Row>
      <TermStar
        message={
          <>
            You qualify for our early sign up discount. Our fee is <span className="text-decoration-line-through">50%</span>&nbsp;<strong>25%</strong> of your
            estimated tax savings.
          </>
        }
      />
      <TermStar message="We'll continue representing your property every tax year until you cancel service. Canceling is easy. 😎" />
    </>
  );
};
