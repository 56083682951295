import * as React from 'react';
import { getApiClient, getSearchApiClient } from '../../utils/GetApiClient';

import { Container } from 'react-bootstrap';
import { navigate } from '@reach/router';
import { trackSignupComplete } from '../../utils/Tracking';
import { loadState, saveState } from '../../utils/Session';

// Base Sign Up State
export interface SignUpState {
  currentStep: number;
  model: SignUpModel;
  settings: SettingResponse[];
  firstSearch: boolean;
  searchCount: number;
  searchResults: SearchResult[];
  selectedProps: SearchResult[];
  isLoading: boolean;
  hideSource: boolean;
}

// API Requests\Responses
export interface SettingResponse {
  id: number;
  name: string;
  open: boolean;
}

export interface SearchResult {
  Id: number;
  CountyId: string;
  SitusAddress: string;
  TotalValue: number;
  OwnerName: string;
  DeedDate: string;
  ServiceOffered: string;
}

export interface SignUpModel {
  numberOfProps: string;
  countyId: number;
  searchTerm: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
}

// Reducer Types
export enum SignUpReducerTypes {
  SaveSettings = 'SAVE_SETTINGS',
  SaveSearchResults = 'SAVE_SEARCH_RESULTS',
  SelectProperty = 'SELECT_PROPERTY',
  DeselectProperty = 'DESELECT_PROPERTY',
  SaveModel = 'SAVE_MODEL',
  SetCurrentStep = 'SET_CURRENT_STEP',
  SetIsLoading = 'SET_IS_LOADING',
  SetHideSource = 'SET_HIDE_SOURCE',
}

export const defaultState: SignUpState = {
  currentStep: 0,
  model: {
    numberOfProps: '',
    countyId: 0,
    searchTerm: '',
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
  } as SignUpModel,
  settings: [],
  firstSearch: false,
  searchCount: 0,
  searchResults: [],
  selectedProps: [],
  isLoading: false,
  hideSource: false,
};

interface SignUpActions {
  getSettings(): void;
  getSearchResults(query: any): void;
  selectProperty(property: SearchResult): void;
  deselectProperty(property: SearchResult): void;
  updateModel(field: string, value: string): void;
  nextStep(): void;
  prevStep(): void;
  saveLead(): void;
  saveSource(source: string): void;
}

interface SignUpContextValue {
  state: SignUpState;
  dispatch: React.Dispatch<any>;
  actions: SignUpActions;
}

const SignUpContext = React.createContext<SignUpContextValue>({
  state: {} as SignUpState,
  dispatch: () => null,
  actions: {
    getSettings: () => null,
    getSearchResults: () => null,
    selectProperty: () => null,
    deselectProperty: () => null,
    updateModel: () => null,
    nextStep: () => null,
    prevStep: () => null,
    saveLead: () => null,
    saveSource: () => null,
  },
});

const signUpStateReducer: React.Reducer<SignUpState, any> = (state: SignUpState, action: any) => {
  switch (action.type) {
    case SignUpReducerTypes.SaveSettings: {
      return saveState('sign-up', { ...state, settings: action.payload });
    }
    case SignUpReducerTypes.SaveSearchResults: {
      return saveState('sign-up', { ...state, firstSearch: true, searchCount: action.payload.count, searchResults: action.payload.values, isLoading: false });
    }
    case SignUpReducerTypes.SelectProperty: {
      return saveState('sign-up', { ...state, selectedProps: [...state.selectedProps, action.payload] });
    }
    case SignUpReducerTypes.DeselectProperty: {
      return saveState('sign-up', { ...state, selectedProps: state.selectedProps.filter((i) => i.Id !== action.payload.Id) });
    }
    case SignUpReducerTypes.SaveModel: {
      var newState = state.model;
      newState[action.payload.field] = action.payload.value;
      return saveState('sign-up', { ...state, model: newState });
    }
    case SignUpReducerTypes.SetCurrentStep: {
      return saveState('sign-up', { ...state, currentStep: action.payload });
    }
    case SignUpReducerTypes.SetIsLoading: {
      return saveState('sign-up', { ...state, isLoading: true });
    }
    case SignUpReducerTypes.SetHideSource: {
      return saveState('sign-up', { ...state, hideSource: true });
    }
    default:
      return state;
  }
};

interface SignUpProviderProps {
  children: React.ReactNode;
}

export const SignUpProvider: React.FunctionComponent<SignUpProviderProps> = (props: SignUpProviderProps) => {
  const [state, dispatch] = React.useReducer(signUpStateReducer, loadState('sign-up', defaultState));

  function getSettings(): void {
    getApiClient()
      .get<SettingResponse[]>('/api/sign-up/settings?dt=' + new Date().getTime())
      .then((response) => {
        //response.data.push({ id: 999999, name: 'My county is not listed', open: true });
        dispatch({ type: SignUpReducerTypes.SaveSettings, payload: response.data });
      })
      .catch((error) => console.log(error));
  }

  function getSearchResults(query: string): void {
    updateModel('searchTerm', query);
    //dispatch({ type: SignUpReducerTypes.SetIsLoading, payload: null });

    const request = {
      select: 'Id,CountyId,SitusAddress,OwnerName,DeedDate,ServiceOffered,TotalValue',
      top: 12,
      fuzzy: false,
      highlightPostTag: '</b>',
      highlightPreTag: '<b>',
      suggesterName: 'sg',
      search: query,
      filter: "CountyId eq '" + state.model.countyId + "'",
    };

    getSearchApiClient()
      .post<any>('/cad-data-autocomplete/docs/suggest?api-version=2023-11-01', request)
      .then((response) => {
        dispatch({ type: SignUpReducerTypes.SaveSearchResults, payload: { count: response.data['@odata.count'], values: response.data.value } });
      })
      .catch((error) => console.log(error));
  }

  function selectProperty(model: SearchResult): void {
    dispatch({ type: SignUpReducerTypes.SelectProperty, payload: model });
  }

  function deselectProperty(model: SearchResult): void {
    dispatch({ type: SignUpReducerTypes.DeselectProperty, payload: model });
  }

  function updateModel(field: string, value: any): void {
    dispatch({ type: SignUpReducerTypes.SaveModel, payload: { field, value } });
  }

  function nextStep(): void {
    dispatch({ type: SignUpReducerTypes.SetCurrentStep, payload: state.currentStep + 1 });
    navigate(window.location.href.split('#')[0] + '#step' + (state.currentStep + 1), { replace: true });
    window.scrollTo(0, 0);
  }

  function prevStep(): void {
    const prevStepNum = state.currentStep - 1;
    dispatch({ type: SignUpReducerTypes.SetCurrentStep, payload: prevStepNum });
    let hash = '';
    if (prevStepNum > 0) {
      hash = '#step' + prevStepNum;
    }
    navigate(window.location.href.split('#')[0] + hash, { replace: true });
    window.scrollTo(0, 0);
  }

  function saveLead(): void {
    const params = new URLSearchParams(window.location.search);

    const request = {
      firstName: state.model.firstName,
      lastName: state.model.lastName,
      email: state.model.email,
      phone: state.model.phone,
      numberOfProperties: state.selectedProps.length.toString(),
      propertyIds: state.selectedProps.map((v) => v.Id).join(','),
      referralSource: params.has('r') ? params.get('r') : null,
    };

    getApiClient()
      .post<any>('/api/sign-up/save', request)
      .then((response) => {
        trackSignupComplete();
        nextStep();
      })
      .catch((error) => console.log(error));
  }

  function saveSource(source: string): void {
    const request = {
      sourceOption: source,
    };

    getApiClient()
      .post<any>('/api/sign-up/source-save', request)
      .then((response) => {
        dispatch({ type: SignUpReducerTypes.SetHideSource, payload: null });
      })
      .catch((error) => console.log(error));
  }

  return (
    <SignUpContext.Provider
      value={{
        state,
        dispatch,
        actions: {
          getSettings,
          getSearchResults,
          selectProperty,
          deselectProperty,
          updateModel,
          nextStep,
          prevStep,
          saveLead,
          saveSource,
        },
      }}
    >
      <Container fluid="lg" className="signup mb-auto">
        {props.children}
      </Container>
    </SignUpContext.Provider>
  );
};

export const useSignUp = (): SignUpContextValue => React.useContext(SignUpContext);
