import * as React from 'react';

import { Col, Row } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { faCheck } from '@fortawesome/pro-regular-svg-icons';
import { useScreenInfo } from '../../utils/ScreenInfo';
import { useSignUp } from './SignUpContext';
import { TermStar } from './TermStar';

export const TermsOfService: React.FunctionComponent = () => {
  const { state } = useSignUp();
  const screen = useScreenInfo();

  const params = new URLSearchParams(window.location.search);
  const referral = params.has('r') ? params.get('r') : null;
  const isSFMC = referral === 'SFMC';
  const isCGEMP = referral === 'CG-EMP';
  const isCGHH = referral === 'CG-HH';

  const hasReferral = isSFMC || isCGEMP || isCGHH;

  return (
    <>
      {state.selectedProps.length > 1 && (
        <>
          <Row>
            <Col md={6}>
              <div className={`text-center ${screen.isPhone ? 'my-1' : 'my-4'}`}>
                <span className="fs-5">
                  You only pay if <br />
                  we save you money!
                </span>
                <div className="display-4">
                  40%
                  <div className="d-inline-flex align-items-start flex-column ms-1 align-middle " style={{ width: 30, marginTop: -10 }}>
                    <div className="fs-6">&nbsp;</div>
                    <div className="fs-6 align-bottom mt-1 text-muted">
                      <del>50%</del>
                    </div>
                  </div>
                </div>
                <span className="fs-4 ms-2">Of Your Estimated Tax Savings</span>
              </div>
            </Col>
            <Col md={6}>
              <div style={{ width: screen.isPhone ? '100%' : 360 }} className={classNames({ 'mx-auto': screen.isPhone })}>
                <div className="d-flex bg-white rounded-top mt-3">
                  <span className="text-orange border-end border-dark py-1 px-4 me-3">
                    <FontAwesomeIcon icon={faCheck} />
                  </span>
                  <span className="d-inline p-1 small">Unlimited Client Support</span>
                </div>
                <div className="d-flex bg-light">
                  <span className="text-orange border-end border-dark py-1 px-4 me-3">
                    <FontAwesomeIcon icon={faCheck} />
                  </span>
                  <span className="d-inline p-1 small">Appointed as Your Tax Representative</span>
                </div>
                <div className="d-flex bg-white">
                  <span className="text-orange border-end border-dark py-1 px-4 me-3">
                    <FontAwesomeIcon icon={faCheck} />
                  </span>
                  <span className="d-inline p-1 small">File Protest</span>
                </div>
                <div className="d-flex bg-light">
                  <span className="text-orange border-end border-dark py-1 px-4 me-3">
                    <FontAwesomeIcon icon={faCheck} />
                  </span>
                  <span className="d-inline p-1 small">Review & Analyze Evidence</span>
                </div>
                <div className="d-flex bg-white">
                  <span className="text-orange border-end border-dark py-1 px-4 me-3">
                    <FontAwesomeIcon icon={faCheck} />
                  </span>
                  <span className="d-inline p-1 small">Prepare & Present Your Appeal</span>
                </div>
                <div className="d-flex bg-light rounded-bottom mb-3">
                  <span className="text-orange border-end border-dark py-1 px-4 me-3">
                    <FontAwesomeIcon icon={faCheck} />
                  </span>
                  <span className="d-inline p-1 small">Take Back Control of Your Property Taxes</span>
                </div>
              </div>
            </Col>
          </Row>
          <TermStar
            message={
              <>
                You qualify for our early sign up discount. Our fee is <span className="text-decoration-line-through">50%</span>&nbsp;<strong>40%</strong> of
                your estimated tax savings.
              </>
            }
          />
          <TermStar message="We'll continue representing your property every tax year until you cancel service. Canceling is easy. 😎" />
        </>
      )}

      {state.selectedProps.length === 1 && state.selectedProps[0].ServiceOffered.includes('$295 2YR') && !hasReferral && (
        <>
          <Row>
            <Col md={6}>
              <div className={`text-center ${screen.isPhone ? 'my-1' : 'my-5'}`}>
                <span className="fs-4 ms-2">Our Best Deal</span>
                <div className={`${screen.isPhone ? 'display-1' : 'display-4'}`}>
                  $295
                  <div className="d-inline-flex align-items-start flex-column ms-1 align-middle " style={{ width: 60, marginTop: -10 }}>
                    <div className="fs-6 badge bg-orange">-50%</div>
                    <div className="fs-6 align-bottom mt-1 text-muted">
                      <del>$590</del>
                    </div>
                  </div>
                </div>
                <span className="fs-4 ms-2">
                  For{' '}
                  <i>
                    <u>Two Years</u>
                  </i>{' '}
                  of Service
                </span>
              </div>
            </Col>
            <Col md={6}>
              <div style={{ width: screen.isPhone ? '100%' : 360 }} className={classNames({ 'mx-auto': screen.isPhone })}>
                <div className="d-flex bg-white rounded-top mt-3">
                  <span className="text-orange border-end border-dark py-1 px-4 me-3">
                    <FontAwesomeIcon icon={faCheck} />
                  </span>
                  <span className="d-inline p-1 small">Unlimited Client Support</span>
                </div>
                <div className="d-flex bg-light">
                  <span className="text-orange border-end border-dark py-1 px-4 me-3">
                    <FontAwesomeIcon icon={faCheck} />
                  </span>
                  <span className="d-inline p-1 small">Appointed as Your Tax Representative</span>
                </div>
                <div className="d-flex bg-white">
                  <span className="text-orange border-end border-dark py-1 px-4 me-3">
                    <FontAwesomeIcon icon={faCheck} />
                  </span>
                  <span className="d-inline p-1 small">
                    File Protest {new Date().getFullYear()} & {new Date().getFullYear() + 1}
                  </span>
                </div>
                <div className="d-flex bg-light">
                  <span className="text-orange border-end border-dark py-1 px-4 me-3">
                    <FontAwesomeIcon icon={faCheck} />
                  </span>
                  <span className="d-inline p-1 small">Review & Analyze Evidence</span>
                </div>
                <div className="d-flex bg-white">
                  <span className="text-orange border-end border-dark py-1 px-4 me-3">
                    <FontAwesomeIcon icon={faCheck} />
                  </span>
                  <span className="d-inline p-1 small">Prepare & Present Your Appeal</span>
                </div>
                <div className="d-flex bg-light rounded-bottom mb-3">
                  <span className="text-orange border-end border-dark py-1 px-4 me-3">
                    <FontAwesomeIcon icon={faCheck} />
                  </span>
                  <span className="d-inline p-1 small">Take Back Control of Your Property Taxes</span>
                </div>
              </div>
            </Col>
          </Row>

          <TermStar
            message={
              <>
                You pay a flat fee of only $295 for{' '}
                <strong>
                  <u>two years</u>
                </strong>{' '}
                of service!
              </>
            }
          />
          <TermStar
            message={
              <>
                Our fee is typically $295 for just one year, but this year we are including{' '}
                <strong>
                  <u>two years of protest services for the price of one.</u>
                </strong>
              </>
            }
          />
          <TermStar
            message="We are offering this discount today, because just about everything else you pay for costs more…We believe the opportunity for tax relief
                  should not."
          />
        </>
      )}

      {state.selectedProps.length === 1 && (state.selectedProps[0].ServiceOffered.includes('CF + SF') || hasReferral) && (
        <>
          <Row>
            <Col md={6}>
              <div className={`text-center ${screen.isPhone ? 'my-1' : 'my-5'}`}>
                <span className="fs-4">Our Best Deal</span>
                <div className="display-4">
                  40%
                  <div className="d-inline-flex align-items-start flex-column ms-1 align-middle " style={{ width: 30, marginTop: -10 }}>
                    <div className="fs-6">&nbsp;</div>
                    <div className="fs-6 align-bottom mt-1 text-muted">
                      <del>50%</del>
                    </div>
                  </div>
                </div>
                <span className="fs-4 ms-2">Of Your Estimated Tax Savings</span>
              </div>
            </Col>

            <Col>
              <div style={{ width: screen.isPhone ? '100%' : 360 }}>
                <div className="d-flex bg-white rounded-top mt-3">
                  <span className="text-orange border-end border-dark py-1 px-4 me-3">
                    <FontAwesomeIcon icon={faCheck} />
                  </span>
                  <span className="d-inline p-1 small">Unlimited Client Support</span>
                </div>
                <div className="d-flex bg-light">
                  <span className="text-orange border-end border-dark py-1 px-4 me-3">
                    <FontAwesomeIcon icon={faCheck} />
                  </span>
                  <span className="d-inline p-1 small">Appointed as Your Tax Representative</span>
                </div>
                <div className="d-flex bg-white">
                  <span className="text-orange border-end border-dark py-1 px-4 me-3">
                    <FontAwesomeIcon icon={faCheck} />
                  </span>
                  <span className="d-inline p-1 small">File {new Date().getFullYear()} Protest</span>
                </div>
                <div className="d-flex bg-light">
                  <span className="text-orange border-end border-dark py-1 px-4 me-3">
                    <FontAwesomeIcon icon={faCheck} />
                  </span>
                  <span className="d-inline p-1 small">Review & Analyze Evidence</span>
                </div>
                <div className="d-flex bg-white">
                  <span className="text-orange border-end border-dark py-1 px-4 me-3">
                    <FontAwesomeIcon icon={faCheck} />
                  </span>
                  <span className="d-inline p-1 small">Prepare & Present Your Appeal</span>
                </div>
                <div className="d-flex bg-light rounded-bottom mb-3">
                  <span className="text-orange border-end border-dark py-1 px-4 me-3">
                    <FontAwesomeIcon icon={faCheck} />
                  </span>
                  <span className="d-inline p-1 small">Take Back Control of Your Property Taxes</span>
                </div>
              </div>
            </Col>
          </Row>
          <TermStar
            message={
              <>
                You qualify for our early sign up discount. Our fee is <span className="text-decoration-line-through">50%</span>&nbsp;<strong>40%</strong> of
                your estimated tax savings, along with a small service fee ($50).
              </>
            }
          />
          <TermStar message="The service fee is to be credited to any contingent fee resulting from a successful appeal." />
        </>
      )}

      {state.selectedProps.length === 1 && state.selectedProps[0].ServiceOffered.includes('Capped') && (
        <div className="text-navy" style={{ fontWeight: 600, textAlign: 'center', fontSize: 14, fontStyle: 'italic' }}>
          You must appeal your {new Date().getFullYear()} assessment to chip away at the market value in order to position yourself to receive any tax relief in{' '}
          {new Date().getFullYear() + 1} and {new Date().getFullYear() + 2}. Otherwise, your assessed value will continue to increase year after year until it
          catches up to your market value.
        </div>
      )}
    </>
  );
};
