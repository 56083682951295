export const loadState = (key: string, defaultState: any): any => {
  try {
    const serializedState = localStorage.getItem(key);
    if (serializedState === null) {
      saveState(key, defaultState);
      return defaultState;
    }
    return JSON.parse(serializedState);
  } catch (err) {
    return defaultState;
  }
};

export const saveState = (key: string, state: any): any => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem(key, serializedState);
    return state;
  } catch (err) {
    console.error(err);
  }
};
