/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';

import { Button, Col, Collapse, Form, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import classNames from 'classnames';
import { useScreenInfo } from '../../utils';
import { SearchResult, useSignUp } from './SignUpContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle, faInfoCircle, faTimesCircle } from '@fortawesome/pro-regular-svg-icons';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';

export const Step1: React.FunctionComponent = () => {
  const { state, actions } = useSignUp();
  const screen = useScreenInfo();
  const [selectedItems, setSelectedItems] = React.useState([]);
  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    actions.getSettings();
  }, []);

  if (state.currentStep !== 1) {
    return <></>;
  }

  const getCountyName = (id: string) => {
    var result = state.settings.filter((f) => f.id === parseInt(id));
    return result.length > 0 ? result[0].name : '';
  };

  const isSelected = (id: number): boolean => {
    var result = state.selectedProps.filter((f) => f.Id === id);
    return result.length > 0;
  };

  const canSelectProps = () => {
    return state.model.countyId > 0 && Array.isArray(state.selectedProps) && state.selectedProps.length < 30;
  };

  const showTerms = () => {
    //return state.model.numberOfProps !== '' && Array.isArray(state.selectedProps) && state.selectedProps.length === parseInt(state.model.numberOfProps);
    return Array.isArray(state.selectedProps) && state.selectedProps.length > 0;
  };

  const handleNumberOfPropsChange = (value: string) => {
    actions.updateModel('numberOfProps', value);

    if (value === '>5') {
      document.location.href = '/sign-issue-question';
    }
  };

  const handleCountyChange = (value: number) => {
    actions.updateModel('countyId', value.toString());

    if (value === 999999) {
      document.location.href = '/sign-issue-question';
    }
  };

  return (
    <>
      <h4>Step 1: Property Search</h4>
      <Row>
        <Col md={6}>
          <Form.Select
            size="lg"
            value={state.model.countyId}
            onChange={(event) => handleCountyChange(parseInt(event.currentTarget.value))}
            style={{ marginBottom: 0 }}
          >
            <option>Select Your County</option>
            {state.settings.map((s) => (
              <option key={s.id} value={s.id} disabled={!s.open} className={classNames({ 'fw-bold': s.open })}>
                {s.name}
              </option>
            ))}
          </Form.Select>
          <div style={{ fontSize: 14, fontStyle: 'italic', fontWeight: 'bold', marginTop: 4, marginBottom: 16 }}>
            The counties listed have extended deadlines and are still available for appeal.
          </div>
        </Col>
        <Col md={6}>
          <AsyncTypeahead
            id="search-address"
            size="lg"
            filterBy={() => true}
            isLoading={state.isLoading}
            labelKey="SitusAddress"
            minLength={3}
            disabled={!canSelectProps()}
            onSearch={(query) => {
              actions.getSearchResults(query);
            }}
            selected={selectedItems}
            onChange={(selectedResult: SearchResult) => {
              actions.selectProperty(selectedResult[0]);
              setOpen(false);
            }}
            options={state.searchResults.filter((f) => !state.selectedProps.map((m) => m.Id).includes(f.Id))}
            placeholder="Enter Address or a Quick Search Code"
            renderMenuItemChildren={(r: SearchResult, index) => (
              <>
                <div>
                  <div key={index} style={{ display: 'flex', justifyContent: 'space-between', width: '100%', borderBottom: '1px solid #000' }}>
                    <div>
                      <div className="fst-italic text-truncate">{r.SitusAddress}</div>

                      <div className="text-truncate">
                        Owner:
                        {r.OwnerName.length > 19 ? (
                          <OverlayTrigger
                            placement="top"
                            overlay={
                              <Tooltip id={`tooltip-${r.Id}`} className="fst-italic">
                                {r.OwnerName}
                              </Tooltip>
                            }
                          >
                            <span className="fst-italic">{r.OwnerName.substring(0, 19)}</span>
                          </OverlayTrigger>
                        ) : (
                          <span className="fst-italic">{r.OwnerName}</span>
                        )}
                      </div>
                    </div>
                    {!screen.isPhone && (
                      <div className="text-end">
                        <div>
                          County: <span className="fst-italic">{getCountyName(r.CountyId)}</span>
                        </div>
                        <div>
                          Deed Date: <span className="fst-italic">{r.DeedDate.replace('T00:00:00Z', '')}</span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </>
            )}
          />

          <Button variant="link" className="link-button" onClick={() => setOpen(!open)}>
            Can’t find your property or need help?
          </Button>
          <Collapse in={open}>
            <div style={{ fontSize: 14 }}>
              Try adjusting your search terms 🙂
              <ul>
                <li>Abbreviating words such as “East” to “E” or “Road” to “Rd”</li>
                <li>For example: If your address is 1234 East Apple Drive try 1234 E Apple.</li>
                <li>
                  Don't see your property or your name as the owner? Click <a href="/sign-issue-question/">here</a>.
                </li>
              </ul>
            </div>
          </Collapse>
        </Col>
      </Row>

      {Array.isArray(state.selectedProps) && state.selectedProps.length > 0 && (
        <div className="bg-white p-3 mt-3 mb-3" style={{ borderRadius: 24 }}>
          <h5 style={{ fontSize: screen.isPhone ? '1.1rem' : '1.25rem', marginBottom: screen.isPhone ? 0 : '.5rem' }}>
            Your Selected Properties for Tax Protest:
          </h5>

          <Row>
            {state.selectedProps.map((r, index) => (
              <Col md={6} key={r.Id}>
                <div className={`search-result ${isSelected(r.Id) ? 'selected' : ''} ${screen.isPhone || index > 1 ? 'mt-3' : ''}`}>
                  <div className="font-bitter" style={{ fontSize: 38, marginTop: -8, marginBottom: -8, marginRight: 16 }}>
                    {index + 1}
                  </div>
                  <div
                    style={{
                      display: screen.isPhone ? 'block' : 'flex',
                      justifyContent: 'space-between',
                      width: '100%',
                    }}
                  >
                    <div>
                      <div className="fst-italic text-truncate">{r.SitusAddress}</div>

                      <div className="text-truncate">
                        Owner:
                        {r.OwnerName?.length > 16 ? (
                          <OverlayTrigger
                            placement="top"
                            overlay={
                              <Tooltip id={`tooltip-${r.Id}`} className="fst-italic">
                                {r.OwnerName}
                              </Tooltip>
                            }
                          >
                            <span className="fst-italic">{r.OwnerName.substring(0, 16)}</span>
                          </OverlayTrigger>
                        ) : (
                          <span className="fst-italic">{r.OwnerName}</span>
                        )}
                      </div>
                    </div>
                    <div className={`${screen.isPhone ? 'text-start' : 'text-end'}`}>
                      <div>
                        County: <span className="fst-italic">{getCountyName(r.CountyId)}</span>
                      </div>
                      <div>
                        Deed Date: <span className="fst-italic">{r.DeedDate?.replace('T00:00:00Z', '')}</span>
                      </div>
                    </div>
                  </div>

                  <button type="button" onClick={() => actions.deselectProperty(r)}>
                    {isSelected(r.Id) ? <FontAwesomeIcon icon={faTimesCircle} size="2x" /> : <FontAwesomeIcon icon={faCircle} size="2x" />}
                  </button>
                </div>
              </Col>
            ))}
            {state.selectedProps.length === 1 && (
              <Col md={6}>
                <div className={`search-result ${screen.isPhone ? 'mt-3' : ''}`} style={{ borderStyle: 'dashed', maxHeight: screen.isPhone ? 107 : 68 }}>
                  <FontAwesomeIcon icon={faInfoCircle} size="2x" style={{ marginTop: 6, marginRight: 4 }} />

                  <div>
                    <div className="font-bitter" style={{ fontSize: 17 }}>
                      Have Another Property?{screen.isPhone && <br />} Enter the Address Above.
                    </div>
                    <div style={{ fontSize: screen.isPhone ? 11 : 12 }}>You can sign-up multiple properties during this process.</div>
                  </div>
                </div>
              </Col>
            )}
          </Row>
        </div>
      )}

      {state.selectedProps.length > 0 && (
        <div
          className={classNames('d-flex mt-3 footer-btns', {
            'justify-content-between': !screen.isPhone,
            'flex-column': screen.isPhone,
          })}
        >
          {!screen.isPhone && (
            <Button variant="secondary" onClick={actions.prevStep}>
              Back to Intro
            </Button>
          )}
          {showTerms() && (
            <Button className={classNames({ 'mb-3': screen.isPhone })} onClick={actions.nextStep}>
              Proceed to Step 2: {screen.isPhone && <br />} Review Pricing + Contact Info
            </Button>
          )}
          {screen.isPhone && (
            <Button variant="secondary" onClick={actions.prevStep}>
              Back to Intro
            </Button>
          )}
        </div>
      )}
    </>
  );
};
