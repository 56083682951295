import * as React from 'react';

import { Col, Row } from 'react-bootstrap';
import { faCheck, faFlag } from '@fortawesome/pro-regular-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { useSignUp } from './SignUpContext';
import { useScreenInfo } from '../../utils';

export const Stepper: React.FunctionComponent = () => {
  const { state } = useSignUp();
  const screen = useScreenInfo();

  if (state.currentStep === 0) {
    return <></>;
  }

  return (
    <>
      <Row className="stepper mb-5">
        <Col xs={3}>
          <div className={`d-flex ${screen.isPhone ? 'flex-column' : ''} align-items-center`}>
            <div className="flex-shrink-0">
              <div className={classNames('stepper-icon', { active: state.currentStep === 1 })}>
                {state.currentStep > 1 ? <FontAwesomeIcon icon={faCheck} /> : '1'}
              </div>
            </div>
            <div className={`${screen.isPhone ? 'mt-2 text-center' : 'flex-grow-1 ms-3'}`}>
              <div className="stepper-title">{screen.isPhone ? 'Select Address' : 'Step 1'}</div>
              {!screen.isPhone && <div className="stepper-desc">Property Search</div>}
            </div>
          </div>
        </Col>
        <Col xs={3}>
          <div className={`d-flex ${screen.isPhone ? 'flex-column' : ''} align-items-center`}>
            <div className="flex-shrink-0">
              <div className={classNames('stepper-icon', { active: state.currentStep === 2 })}>
                {state.currentStep > 2 ? <FontAwesomeIcon icon={faCheck} /> : '2'}
              </div>
            </div>
            <div className={`${screen.isPhone ? 'mt-2 text-center' : 'flex-grow-1 ms-3'}`}>
              <div className="stepper-title">{screen.isPhone ? 'Pricing' : 'Step 2'}</div>
              {!screen.isPhone && <div className="stepper-desc">Pricing & Terms</div>}
            </div>
          </div>
        </Col>
        <Col xs={3}>
          <div className={`d-flex ${screen.isPhone ? 'flex-column' : ''} align-items-center`}>
            <div className="flex-shrink-0">
              <div className={classNames('stepper-icon', { active: state.currentStep === 3 })}>
                {state.currentStep > 3 ? <FontAwesomeIcon icon={faCheck} /> : '3'}
              </div>
            </div>
            <div className={`${screen.isPhone ? 'mt-2 text-center' : 'flex-grow-1 ms-3'}`}>
              <div className="stepper-title">{screen.isPhone ? 'Confirm Details' : 'Step 3'}</div>
              {!screen.isPhone && <div className="stepper-desc">Review</div>}
            </div>
          </div>
        </Col>
        <Col xs={3}>
          <div className={`d-flex ${screen.isPhone ? 'flex-column' : ''} align-items-center`}>
            <div className="flex-shrink-0">
              <div className={classNames('stepper-icon', { active: state.currentStep === 4 })}>
                {state.currentStep === 4 ? <FontAwesomeIcon icon={faCheck} /> : <FontAwesomeIcon icon={faFlag} />}
              </div>
            </div>
            <div className={`${screen.isPhone ? 'mt-2 text-center' : 'flex-grow-1 ms-3'}`}>
              <div className="stepper-title">Complete</div>
              {!screen.isPhone && <div className="stepper-desc">Thank You</div>}
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};
