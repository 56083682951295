import * as React from 'react';

import { useSignUp } from './SignUpContext';
import { useScreenInfo } from '../../utils';
import { useTimeout } from 'usehooks-ts';

export const Step4Events: React.FunctionComponent = () => {
  const { state, actions } = useSignUp();
  const screen = useScreenInfo();
  const [selectedSource, setSelectedSource] = React.useState('');

  useTimeout(() => {
    localStorage.removeItem('sign-up');
  }, 1000);

  useTimeout(() => {
    const params = new URLSearchParams(window.location.search);
    document.location = '/events/?r=' + params.get('r');
  }, 1000 * 5);

  if (state.currentStep !== 4) {
    return null;
  }

  return (
    <>
      <div className="bg-light-blue p-3" style={{ borderRadius: 24 }}>
        <h4 className="mt-1">Engagement documents will be emailed to you shortly. 🎉</h4>
        <div className="fs-6 ms-3">Once you have signed your engagement documents, we will file your tax protest and begin preparing your case.</div>
        <h5 className="mt-4">This page will redirect shortly...</h5>
      </div>
    </>
  );
};
