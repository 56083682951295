import * as React from 'react';

import { Button, Col, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import classNames from 'classnames';
import { useScreenInfo } from '../../utils';
import { useSignUp } from './SignUpContext';
import { TermsOfService } from './TermsOfService';
import { TermStar } from './TermStar';
import { TermsOfServiceForEvents } from './TermsOfServiceForEvents';

interface Step3Props {
  isEvent?: boolean;
}

export const Step3: React.FC<Step3Props> = (props) => {
  const { state, actions } = useSignUp();
  const screen = useScreenInfo();

  if (state.currentStep !== 3) {
    return <></>;
  }

  const getCountyName = (id: string) => {
    var result = state.settings.filter((f) => f.id === parseInt(id));
    return result.length > 0 ? result[0].name : '';
  };

  return (
    <>
      <h4 className="mb-3">Step 3: Review & Complete</h4>
      <div className="bg-light-orange p-3 mt-3 mb-3" style={{ borderRadius: 24 }}>
        <h5>Summary Terms of Service</h5>
        <TermStar
          message={
            <>
              Upon clicking "Submit," you will be <strong className="text-decoration-underline">emailed</strong> our full Terms of Service for your review and
              e-signature.
            </>
          }
        />
        <TermStar
          message={
            <>
              We <strong className="text-decoration-underline">require your electronic signature before engaging</strong> in our service.
            </>
          }
        />
        <TermStar message="Do not file your own protest. We will take care of it for you." />

        <Button className={classNames('mt-2', { 'mb-3': screen.isPhone })} onClick={actions.saveLead}>
          Submit Request for Tax Protest
        </Button>
      </div>
      <div className="bg-light-blue p-3 mt-3 mb-3" style={{ borderRadius: 24 }}>
        <h5 className="mb-3">Your Selected Properties:</h5>
        <Row>
          {state.selectedProps.map((r, index) => (
            <Col key={r.Id} md="6">
              <div className={`search-result bg-white mb-2`}>
                <div className="font-bitter" style={{ fontSize: 38, marginTop: -8, marginBottom: -8, marginRight: 16 }}>
                  {index + 1}
                </div>
                <div style={{ display: screen.isPhone ? 'block' : 'flex', justifyContent: 'space-between', width: '100%' }}>
                  <div>
                    <div className="fst-italic text-truncate">{r.SitusAddress}</div>

                    <div className="text-truncate">
                      Owner:
                      {r.OwnerName.length > 19 ? (
                        <OverlayTrigger
                          placement="top"
                          overlay={
                            <Tooltip id={`tooltip-${r.Id}`} className="fst-italic">
                              {r.OwnerName}
                            </Tooltip>
                          }
                        >
                          <span className="fst-italic">{r.OwnerName.substring(0, 19)}</span>
                        </OverlayTrigger>
                      ) : (
                        <span className="fst-italic">{r.OwnerName}</span>
                      )}
                    </div>
                  </div>
                  <div className={`${screen.isPhone ? 'text-start' : 'text-end'}`}>
                    <div>
                      County: <span className="fst-italic">{getCountyName(r.CountyId)}</span>
                    </div>
                    <div>
                      Deed Date: <span className="fst-italic">{r.DeedDate.replace('T00:00:00Z', '')}</span>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          ))}
        </Row>
      </div>
      <div className="bg-light-orange p-3 mt-3 mb-3" style={{ borderRadius: 24 }}>
        {!props.isEvent && <TermsOfService />}
        {props.isEvent && <TermsOfServiceForEvents />}
      </div>
      <div
        className={classNames('d-flex mt-3 footer-btns', {
          'justify-content-between': !screen.isPhone,
          'flex-column': screen.isPhone,
        })}
      >
        {!screen.isPhone && (
          <Button variant="secondary" onClick={actions.prevStep}>
            Back to Step 2
          </Button>
        )}

        <Button className={classNames({ 'mb-3': screen.isPhone })} onClick={actions.saveLead}>
          Submit Request for Tax Protest
        </Button>
        {screen.isPhone && (
          <Button variant="secondary" onClick={actions.prevStep}>
            Back to Step 2
          </Button>
        )}
      </div>
    </>
  );
};
