import * as React from 'react';
import { Col, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/pro-regular-svg-icons';

interface TermStarProps {
  message: React.ReactNode;
}

export const TermStar: React.FC<TermStarProps> = (props: TermStarProps) => {
  return (
    <Row className="mb-2">
      <Col md={12}>
        <div className="d-flex">
          <div style={{ width: 25 }} className="me-1">
            <FontAwesomeIcon icon={faStar} fixedWidth size="lg" className="text-orange" />
          </div>

          <div style={{ marginTop: 2 }}>{props.message}</div>
        </div>
      </Col>
    </Row>
  );
};
