import * as React from 'react';

import { Button, Card, Col, FloatingLabel, Form, Row } from 'react-bootstrap';

import classNames from 'classnames';
import { useScreenInfo } from '../../utils';
import { useSignUp } from './SignUpContext';
import { TermsOfService } from './TermsOfService';
import { TermsOfServiceForEvents } from './TermsOfServiceForEvents';

interface Step2Props {
  isEvent?: boolean;
}

export const Step2: React.FC<Step2Props> = (props) => {
  const { state, actions } = useSignUp();
  const screen = useScreenInfo();
  const [firstNameError, setFirstNameError] = React.useState(false);
  const [lastNameError, setLastNameError] = React.useState(false);
  const [emailError, setEmailError] = React.useState(false);
  const [phoneError, setPhoneError] = React.useState(false);
  const [firstSubmit, setFirstSubmit] = React.useState(false);

  if (state.currentStep !== 2) {
    return <></>;
  }

  const emailRegex =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  const phoneFormat = (phoneNumber: string): string => {
    let cleaned = ('' + phoneNumber).replace(/\D/g, '');
    cleaned = cleaned.substring(0, 10);
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match && cleaned.length === 10) {
      setPhoneError(false);
      return '(' + match[1] + ') ' + match[2] + '-' + match[3];
    }
    setPhoneError(true);
    return cleaned;
  };

  const emailFormat = (email: string): string => {
    const match = email.match(emailRegex);

    if (match !== null) {
      setEmailError(false);
      return email;
    }
    setEmailError(true);
    return email;
  };

  const firstNameChange = (value: string) => {
    actions.updateModel('firstName', value);

    if (state.model.firstName) {
      setFirstNameError(false);
    }
  };

  const lastNameChange = (value: string) => {
    actions.updateModel('lastName', value);

    if (state.model.lastName) {
      setLastNameError(false);
    }
  };

  const handleSubmit = () => {
    setFirstSubmit(true);
    if (!state.model.firstName) {
      setFirstNameError(true);
    }

    if (!state.model.lastName) {
      setLastNameError(true);
    }

    phoneFormat(state.model.phone);

    emailFormat(state.model.email);

    if (
      !emailError &&
      !phoneError &&
      !firstNameError &&
      !lastNameError &&
      state.model.firstName &&
      state.model.lastName &&
      state.model.phone &&
      state.model.email
    ) {
      actions.nextStep();
    }
  };

  return (
    <>
      <h4 className="mb-3">Step 2: Review Pricing + Contact Info</h4>
      <div
        className={classNames('d-flex my-3 footer-btns', {
          'justify-content-between': !screen.isPhone,
          'flex-column': screen.isPhone,
        })}
      >
        {!screen.isPhone && (
          <Button variant="secondary" onClick={actions.prevStep}>
            Back to Step 1
          </Button>
        )}
        <Button className={classNames({ 'mb-3': screen.isPhone })} onClick={handleSubmit}>
          Proceed to Step 3: Review
        </Button>
        {screen.isPhone && (
          <Button variant="secondary" onClick={actions.prevStep}>
            Back to Step 1
          </Button>
        )}
      </div>
      <Card className="bg-light-blue">
        <Card.Body>
          <h4 className="mb-3">Provide Your Contact Information</h4>
          <Row>
            <Col md={6}>
              <FloatingLabel label="First Name" className={screen.isPhone ? 'mb-3' : ''}>
                <Form.Control
                  id="firstName"
                  type="text"
                  value={state.model.firstName}
                  onChange={(event) => firstNameChange(event.currentTarget.value)}
                  isInvalid={firstNameError}
                />
                <Form.Control.Feedback type="invalid">First Name is Required!</Form.Control.Feedback>
              </FloatingLabel>
            </Col>
            <Col md={6}>
              <FloatingLabel label="Last Name" className={screen.isPhone ? 'mb-3' : ''}>
                <Form.Control
                  id="lastName"
                  type="text"
                  value={state.model.lastName}
                  onChange={(event) => lastNameChange(event.currentTarget.value)}
                  isInvalid={lastNameError}
                />
                <Form.Control.Feedback type="invalid">Last Name is Required!</Form.Control.Feedback>
              </FloatingLabel>
            </Col>
          </Row>
          <Row className={screen.isPhone ? '' : 'mt-3'}>
            <Col md={6}>
              <FloatingLabel label="Email" className={screen.isPhone ? 'mb-3' : ''}>
                <Form.Control
                  id="email"
                  type="email"
                  value={state.model.email}
                  onChange={(event) => actions.updateModel('email', emailFormat(event.currentTarget.value))}
                  isInvalid={firstSubmit && emailError}
                />
                <Form.Control.Feedback type="invalid">Invalid Email Address!</Form.Control.Feedback>
              </FloatingLabel>
            </Col>
            <Col md={6}>
              <FloatingLabel label="Phone" className={screen.isPhone ? 'mb-3' : ''}>
                <Form.Control
                  id="phone"
                  type="tel"
                  value={state.model.phone}
                  onChange={(event) => actions.updateModel('phone', phoneFormat(event.currentTarget.value))}
                  isInvalid={firstSubmit && phoneError}
                />
                <Form.Control.Feedback type="invalid">Invalid Phone Number!</Form.Control.Feedback>
              </FloatingLabel>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <div className="bg-light-blue p-3 mt-3 mb-3" style={{ borderRadius: 24 }}>
        {!props.isEvent && <TermsOfService />}
        {props.isEvent && <TermsOfServiceForEvents />}
      </div>
      <div
        className={classNames('d-flex mt-3 footer-btns', {
          'justify-content-between': !screen.isPhone,
          'flex-column': screen.isPhone,
        })}
      >
        {!screen.isPhone && (
          <Button variant="secondary" onClick={actions.prevStep}>
            Back to Step 1
          </Button>
        )}
        <Button className={classNames({ 'mb-3': screen.isPhone })} onClick={handleSubmit}>
          Proceed to Step 3: Review
        </Button>
        {screen.isPhone && (
          <Button variant="secondary" onClick={actions.prevStep}>
            Back to Step 1
          </Button>
        )}
      </div>
    </>
  );
};
