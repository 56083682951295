import * as React from 'react';

import { Col, Row } from 'react-bootstrap';
import { getApiClient, ReviewStars, useScreenInfo } from '../../utils';
import { StaticImage } from 'gatsby-plugin-image';
import { useSignUp } from './SignUpContext';

interface ReviewResponse {
  id: number;
  rating: number;
  totalReviews: number;
}

export const ReviewFooter: React.FunctionComponent = () => {
  const { state } = useSignUp();
  const [reviewData, setReviewData] = React.useState({} as ReviewResponse);
  const screen = useScreenInfo();

  React.useEffect(() => {
    getApiClient()
      .get<ReviewResponse>('/api/reviews/google/' + (screen.isPhone ? 'mobile' : 'desktop'))
      .then((response) => {
        setReviewData(response.data);
      })
      .catch((error) => console.log(error));
  }, [screen.isPhone]);

  return (
    <>
      <Row className="my-4">
        <Col md={{ span: 6, offset: 3 }} style={{ position: 'relative' }} className={`${screen.isPhone ? 'text-center' : ''}`}>
          <div className="d-inline-flex" style={{ width: 204 }}>
            <div className="flex-shrink-0">
              <StaticImage
                src="../../images/logos/google-logo.png"
                height={32}
                width={32}
                alt="google logo"
                loading="lazy"
                className="me-2"
                placeholder="none"
              />
            </div>
            <div className="flex-grow-1 text-start">
              <div>
                <span className="fs-4 me-1 fw-bold mt-5 rating">{reviewData.rating}</span>
                <div className="align-top d-inline-block stars mt-1">
                  <ReviewStars rating={reviewData.rating} />
                </div>
              </div>
            </div>
          </div>
          <div className={`d-inline-flex align-bottom ${screen.isPhone ? 'mt-3' : 'ms-3'}`}>
            <StaticImage
              src="../../images/logos/bbb-seal-horz.png"
              layout={'fixed'}
              height={52}
              alt="bbb logo"
              loading="lazy"
              className="me-2"
              placeholder="none"
            />
          </div>
        </Col>
      </Row>
    </>
  );
};
