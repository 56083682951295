import * as React from 'react';

import { GoogleNoIndex, NavMenu, PageTitle } from '../components/shared';
import { Intro, ReviewFooter, SignUpProvider, Step1, Step2, Step3, Step4Events, Stepper, useSignUp } from '../components/signup';

const SignUpPage = () => {
  if (typeof location !== `undefined`) {
    const params = new URLSearchParams(location.search);
    const referral = params.has('r') ? params.get('r') : null;

    if (!referral) {
      location.href = '/';
    }
  }

  return (
    <>
      <PageTitle />
      <GoogleNoIndex />

      <NavMenu noMenu notFixed solidColor />
      <div className="d-flex align-items-start flex-column">
        <div className="mb-auto w-100">
          <SignUpProvider>
            <SignUpBody />
            <ReviewFooter />
          </SignUpProvider>
        </div>
      </div>
    </>
  );
};

const SignUpBody = () => {
  const { state } = useSignUp();
  return (
    <>
      {state.currentStep !== 0 && <Stepper />}
      {state.currentStep === 0 && <Intro />}
      {state.currentStep === 1 && <Step1 />}
      {state.currentStep === 2 && <Step2 isEvent />}
      {state.currentStep === 3 && <Step3 isEvent />}
      {state.currentStep === 4 && <Step4Events />}
    </>
  );
};

export default SignUpPage;
