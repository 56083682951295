import * as React from 'react';

import { Button, Stack } from 'react-bootstrap';
import { useSignUp } from './SignUpContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faCircle1, faCircle2, faCircle3 } from '@fortawesome/pro-regular-svg-icons';
import { useScreenInfo } from '../../utils';

export const Intro: React.FunctionComponent = () => {
  const { state, actions } = useSignUp();
  const screen = useScreenInfo();

  React.useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const value = params.has('s') ? params.get('s') : null;
    if (value === '1') {
      actions.nextStep();
    }
  });

  if (state.currentStep !== 0) {
    return <></>;
  }

  return (
    <>
      <h1 style={{ textAlign: 'center', fontSize: screen.isPhone ? '1.5rem' : '2.3rem' }}>Sign Up to Reduce your Property Tax Assessment Today</h1>

      <div className="sign-up-card mt-4 d-grid" style={{ maxWidth: 390 }}>
        <Button onClick={actions.nextStep}>
          Find My Property <FontAwesomeIcon icon={faArrowRight} className="ms-2" />{' '}
        </Button>
        <div className="text-center">
          <i>Takes less than 5 minutes!</i>
        </div>
      </div>

      <div className="bg-light-blue" style={{ padding: 24, borderRadius: 24, maxWidth: 560, margin: '1rem auto' }}>
        <h2 className="mb-4">Signing up is fast and easy</h2>
        <Stack>
          <div className="d-flex mb-2">
            <FontAwesomeIcon icon={faCircle1} size="2x" className="text-orange" />
            <h4 className="ms-2">Search for Your Property</h4>
          </div>
          <div className="d-flex mb-2">
            <FontAwesomeIcon icon={faCircle2} size="2x" className="text-orange" />
            <h4 className="ms-2">Review Pricing and Terms</h4>
          </div>
          <div className="d-flex mb-2">
            <FontAwesomeIcon icon={faCircle3} size="2x" className="text-orange" />
            <h4 className="ms-2">Review and Complete Your Sign-Up Request</h4>
          </div>
          <span className="text-line text-center">
            <em>
              Engagement documents will be emailed to you for {!screen.isPhone && <br />}
              electronic signature following your completed signup request
            </em>
          </span>
        </Stack>
      </div>
    </>
  );
};
